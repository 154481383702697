import React from 'react'
import PropTypes from 'prop-types'

const ExternalLink = ({ children, href }) => (
  <a
    href={href}
    target="_blank"
    rel="noreferrer"
    data-event-category="External Links"
  >
    {children}
  </a>
)

ExternalLink.propTypes = {
  children: PropTypes.node.isRequired,
  href: PropTypes.string.isRequired,
}

export default ExternalLink
