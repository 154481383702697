import React from 'react'
import PropTypes from 'prop-types'
import { Badge } from 'react-bootstrap'

const UnavailableBadge = ({ label }) => {
  const variant = label === 'Discontinued' ? 'danger' : 'warning'
  return (
    <Badge variant={variant}>
      {label}
    </Badge>
  )
}

UnavailableBadge.propTypes = {
  label: PropTypes.string.isRequired,
}

export default UnavailableBadge
