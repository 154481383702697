import React from 'react'
import { graphql } from 'gatsby'
import { Container, Row, Col, Tab, Breadcrumb } from 'react-bootstrap'
import { remark } from 'remark'
import recommended from 'remark-preset-lint-recommended'
import remarkHtml from 'remark-html'

import Layout from '../components/layout'
import Seo from '../components/seo'
import Section from '../components/section'
import MetaList from '../components/meta-list'
import StarRating from '../components/star-rating'
import ShareSidebar from '../components/share-sidebar'
import ImageGallery from '../components/image-gallery'
import Prose from '../components/prose'
import RatingBreakdown from '../components/rating-breakdown'
import Tabs from '../components/tabs'
import NutritionFacts from '../components/nutrition-facts'
import AllergenList from '../components/allergen-list'
import AllergenIcon from '../components/allergen-icon'
import ExternalLink from '../components/external-link'
import RelatedContent from '../components/related-content'
import SubscribeCta from '../components/subscribe-cta'
import PublishDate from '../components/publish-date'
import UnavailableBadge from '../components/unavailable-badge'

const ReviewTemplate = ({ data, pageContext }) => {
  const review = data.markdownRemark
  const meta = review.frontmatter
  const { relatedContent } = pageContext
  const slug = pageContext.slug.replace(/\//g, '')
  const canonicalUrl = data.site.siteMetadata.siteUrl + pageContext.slug

  const metaDesc = (meta.meta && meta.meta.description
    ? meta.meta.description
    : meta.summary
  )

  const socialUrlBase = [
    data.site.siteMetadata.siteUrl,
    '/images/social/',
    slug,
    '/',
    slug,
  ].join('')

  const ogImageUrl = `${socialUrlBase}-open-graph.jpg`
  const twitterImageUrl = `${socialUrlBase}-twitter.jpg`
  const pinterestImageUrl = `${socialUrlBase}-pinterest.jpg`

  const ingredients = (
    remark()
      .use(recommended)
      .use(remarkHtml)
      .processSync(meta.ingredients)
      .toString()
  )
  
  let vitamins
  if (meta.vitamins) {
    vitamins = (
      remark()
        .use(recommended)
        .use(remarkHtml)
        .processSync(meta.vitamins)
        .toString()
    )
  }

  const hasAllergens = (
    meta.allergens &&
    (meta.allergens.list || meta.allergens.description)
  )

  const titleBadge = meta.unavailable ? meta.unavailable : 'Review'
  const showIngredientsTab = hasAllergens || ingredients.trim() || vitamins

  return (
    <Layout>
      <Seo
        title={`${meta.title} [${titleBadge}]`}
        description={metaDesc}
        canonicalUrl={canonicalUrl}
        meta={[
          {
            property: 'og:type',
            content: 'article',
          },
          {
            property: 'article:section',
            content: 'S’mores Product Reviews',
          },
          {
            property: 'article:published_time',
            content: meta.publishedOnIso,
          },
          {
            property: 'article:modified_time',
            content: meta.updatedOnIso,
          },
          {
            property: 'article:author',
            content: data.site.siteMetadata.title,
          },
          {
            property: 'og:image',
            content: ogImageUrl,
          },
          {
            property: 'og:image:alt',
            content: meta.title,
          },
          {
            name: 'twitter:card',
            content: 'summary_large_image',
          },
          {
            name: 'twitter:image',
            content: twitterImageUrl,
          },
          {
            name: 'twitter:image:alt',
            content: meta.title,
          },
        ]}
        schema={buildSchema(data, pageContext)}
      />

      <Section className="pb-5">
        <Container>
          <Row>
            <Col md={6} lg={7} className="mb-5 mb-md-0">
              <Breadcrumb>
                <Breadcrumb.Item
                  href="/"
                  linkProps={{ className: "breadcrumb-link" }}
                >
                  Home
                </Breadcrumb.Item>

                <Breadcrumb.Item
                  href="/reviews/"
                  linkProps={{ className: "breadcrumb-link" }}
                >
                  Reviews
                </Breadcrumb.Item>
              </Breadcrumb>

              <h1>{meta.title}</h1>

              <MetaList>
                <MetaList.Item>
                  <PublishDate
                    publishedOn={meta.publishedOn}
                    publishedOnIso={meta.publishedOnIso}
                    modifiedOn={meta.modifiedOn}
                    modifiedOnIso={meta.modifiedOnIso}
                  />
                </MetaList.Item>

                <MetaList.Item>
                  Rating:{' '}
                  <StarRating rating={meta.rating.overall} />
                </MetaList.Item>

                {meta.unavailable ? (
                  <MetaList.Item>
                    <UnavailableBadge label={meta.unavailable} />
                  </MetaList.Item>
                ) : ''}
              </MetaList>

              <ShareSidebar
                title={`Review: ${meta.title}`}
                pinterestMedia={pinterestImageUrl}
              />

              <ImageGallery
                images={meta.imageGallery}
                unavailable={meta.unavailable}
                className="prose-margin"
              />

              <p className="lead mb-5">
                {meta.summary}
              </p>

              {/*
              <InfoBox mb={5}>
                <InfoBox.Body>
                  Google Ad here
                </InfoBox.Body>
              </InfoBox>
              */}

              <Prose hasRawHtml={true}>
                {review.html}
              </Prose>
            </Col>

            <Col md={6} lg={5} className="pl-xl-5">
              <RatingBreakdown ratings={meta.rating} />

              <h2 className="h4">
                The facts
              </h2>
              <Tabs id="facts-tabs">
                {meta.nutritionFacts ? (
                  <Tab
                    eventKey="nutrition"
                    title="Nutrition"
                    className="tab-pane-facts"
                  >
                    <NutritionFacts facts={meta.nutritionFacts} />
                  </Tab>
                ) : ''}

                {showIngredientsTab ? (
                  <Tab
                    eventKey="ingredients"
                    title="Ingredients"
                    className="tab-pane-facts"
                  >
                    {hasAllergens ? (
                      <h3 className="h6">
                        Allergens
                      </h3>
                    ) : ''}

                    {hasAllergens && meta.allergens.list ? (
                      <AllergenList>
                        {meta.allergens.list.map(allergen => (
                          <AllergenList.Item key={allergen.title}>
                            <AllergenIcon icon={allergen.abbr} /> {allergen.title}
                          </AllergenList.Item>
                        ))}
                      </AllergenList>
                    ) : ''}

                    {hasAllergens && meta.allergens.description ? (
                      <p>
                        <strong>{meta.allergens.description}</strong>
                      </p>
                    ) : ''}

                    {ingredients.trim() ? (
                      <>
                        <h3 className="h6">
                          Ingredients
                        </h3>
                        <div
                          dangerouslySetInnerHTML={{ __html: ingredients }}
                          className="mb-3"
                        />
                      </>
                    ) : ''}

                    {vitamins ? (
                      <>
                        <h3 className="h6">
                          Vitamins and minerals
                        </h3>
                        <div dangerouslySetInnerHTML={{ __html: vitamins }} />
                      </>
                    ) : ''}
                  </Tab>
                ) : ''}

                <Tab
                  eventKey="manufacturer"
                  title="Product"
                  className="tab-pane-facts"
                >
                  <dl>
                    <dt>Product</dt>
                    <dd>
                      <p>
                        {meta.product.url ? (
                          <ExternalLink href={meta.product.url}>
                            {meta.product.title}
                          </ExternalLink>
                        ) : meta.product.title}

                      </p>
                      <p>
                        {meta.product.description}
                      </p>
                    </dd>

                    <dt>Brand</dt>
                    <dd>
                      <ExternalLink href={meta.brand.url}>
                        {meta.brand.title}
                      </ExternalLink>
                    </dd>

                    <dt>Manufacturer</dt>
                    <dd>
                      <address>
                        {meta.product.manufacturer.company}<br />

                        {meta.product.manufacturer.address1 ? (
                          <>
                            {meta.product.manufacturer.address1}<br />
                          </>
                        ) : ''}

                        {meta.product.manufacturer.address2 ? (
                          <>
                            {meta.product.manufacturer.address2}<br />
                          </>
                        ) : ''}
                        {meta.product.manufacturer.city},{' '}
                        {meta.product.manufacturer.state}{' '}
                        {meta.product.manufacturer.zip}<br />
                        {meta.product.manufacturer.country}<br />

                        {meta.product.manufacturer.phone ? (
                          <>
                            {meta.product.manufacturer.phone}<br />
                          </>
                        ) : ''}
                      </address>
                    </dd>
                  </dl>
                </Tab>
              </Tabs>

              {showIngredientsTab || meta.nutritionFacts ? (
                <p className="mt-3 small">
                  <em>While we make every effort to provide accurate
                  nutritional and allergen information, we urge you to
                  double-check the product packaging or manufacturer's
                  website before purchase and consumption.</em>
                </p>
              ): ''}

              {relatedContent && relatedContent.length ? (
                <>
                  <h2 className="h4 mt-5">
                    Related
                  </h2>
                  <RelatedContent relatedContent={relatedContent} />
                </>
              ) : ''}
            </Col>
          </Row>
        </Container>
      </Section>

      <SubscribeCta>
        <h2 className="h3 mb-3">
          Get s’more reviews like this one in your inbox
        </h2>
      </SubscribeCta>
    </Layout>
  )
}

function buildSchema(data, pageContext) {
  const site = data.site.siteMetadata
  const review = data.markdownRemark
  const meta = review.frontmatter
  const slug = pageContext.slug.replace(/\//g, '')

  const socialUrlBase = [
    data.site.siteMetadata.siteUrl,
    '/images/social/',
    slug,
    '/',
    slug,
  ].join('')

  const ogImageUrl = `${socialUrlBase}-open-graph.jpg`

  const modifiedOnIso = meta.modifiedOnIso
    ? meta.modifiedOnIso
    : review.publishedOnIso

  const schema = {
    "@context": "https://schema.org/",
    "@type": "Review",
    "datePublished": meta.publishedOnIso,
    "dateModified": modifiedOnIso,
    "name": `${meta.title} Review`,
    "reviewBody": meta.summary,
    "itemReviewed": {
      "@type": "Product",
      "name": meta.title,
      "image": ogImageUrl,
      "priceRange": "$",
      "brand": {
        "@type": "Brand",
        "name": meta.brand.title,
        "url": meta.brand.url,
      },
      "manufacturer": {
        "@type": "Organization",
        "name": meta.product.manufacturer.company,
        "address": {
          "@type": "PostalAddress",
          "addressLocality": meta.product.manufacturer.city,
          "addressRegion": meta.product.manufacturer.state,
          "postalCode": meta.product.manufacturer.zip.toString(),
          "addressCountry": meta.product.manufacturer.country,
        },
        "telephone": meta.product.manufacturer.phone,
      }
    },
    "reviewRating": {
      "@type": "Rating",
      "ratingValue": meta.rating.overall,
    },
    "author": {
      "@type": "Person",
      "name": site.title,
    },
    "publisher": {
      "@type": "Organization",
      "name": site.title,
    }
  }

  if (meta.product.manufacturer.address1) {
    schema["itemReviewed"]["manufacturer"]["address"]["streetAddress"] =
      `${meta.product.manufacturer.address1} ${meta.product.manufacturer.address2}`
  }

  if (meta.product.url) {
    schema["itemReviewed"]["url"] = meta.product.url
  }

  if (meta.product.description) {
    schema["itemReviewed"]["description"] = meta.product.description
  }

  return schema
}

export const query = graphql`
  query ReviewBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    markdownRemark(
      fields: {
        content_type: { eq: "reviews" }
        slug: { eq: $slug }
      }
    ) {
      html
      frontmatter {
        title
        publishedOn: published_on(formatString: "MMMM D, YYYY")
        publishedOnIso: published_on(formatString: "YYYY-MM-DD")
        modifiedOn: modified_on(formatString: "MMMM D, YYYY")
        modifiedOnIso: modified_on(formatString: "YYYY-MM-DD")
        meta {
          description
        }
        unavailable
        imageGallery: image_gallery {
          thumb: file {
            childImageSharp {
              base: gatsbyImageData(width: 70, height: 70, transformOptions: {
                cropFocus: CENTER
              })
              large: gatsbyImageData(width: 93, height: 93, transformOptions: {
                cropFocus: CENTER
              })
            }
          }
          full: file {
            childImageSharp {
              gatsbyImageData(
                width: 687,
                height: 516,
                transformOptions: {cropFocus: CENTER}
              )
            }
          }
          file {
            name
            ext
          }
          alt
        }
        summary
        rating {
          overall
          marshmallow
          chocolate
          graham
        }
        relatedContent: related_content
        brand {
          title
          url
        }
        product {
          title
          url
          description
          manufacturer {
            company
            address1: address_1
            address2: address_2
            city
            state
            zip
            country
            phone
          }
        }
        nutritionFacts: nutrition_facts {
          servingSize: serving_size
          keyFacts: key_facts {
            title
            amount
          }
          facts {
            title
            amount
            percent
            subFacts: sub_facts {
              title
              amount
              percent
            }
          }
          vitamins {
            title
            amount
            percent
          }
        }
        allergens {
          description
          list {
            title
            abbr
          }
        }
        ingredients
        vitamins
      }
    }
  }
`

export default ReviewTemplate
